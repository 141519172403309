* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.background {
  height: 100vh;
  overflow: hidden;
  position: absolute;
  background-color: #1a1a1a;
  width: 100%;
  top: 0;
  z-index: -1;
}

.center_container {
  background-color: #1a1a1a;
}

.main_container {
  display: grid;
  grid-template-rows: repeat(6);
  grid-template-columns: repeat(2, 46vw);
  justify-items: center;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.modal-content {
  background: white !important;
  padding: 20px;
  border-radius: 8px;
  max-width: 90vw;
  width: 100%;
  height: auto;
  text-align: center;
  z-index: 1001;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 1);
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.headers3 {
  font-size: 1.3rem;
  text-align: center;
  font-weight: bolder;
  color: white;
  margin-bottom: 0;
}

.paragraph_text {
  text-align: left;
  font-size: 1rem;
  color: white;
  margin-bottom: 0;
}

.ccard {
  width: 45vw;
  height: 8vh;
  border-radius: 50px;
  background-color: #53535370;
  box-shadow: 0 5px 10px -3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

article {
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.personalAJ {
  border-radius: 50%;
}
.homeimg {
  border-radius: 50%;
  max-height: 60px;
  max-width: 60px;
  /* width: auto; */
  /* height: auto; */
  margin-left: 10px;
  object-fit: contain;
}

div.ccard:hover {
  background-color: rgb(122, 122, 122);
}
@media only screen and (max-device-width: 800px) {
  .main_container {
    display: grid;
    grid-template-rows: repeat(6, auto);
    grid-template-columns: repeat(1, 50vw);
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
  .headers3 {
    font-size: 1rem;
    text-align: left;
    font-weight: bolder;
    color: white;
  }
  .paragraph_text {
    text-align: left;
    font-size: 60%;
    color: white;
  }

  .ccard {
    width: 80vw;
    height: 12vw;
    border-radius: 50px;
    background-color: #53535370;
    box-shadow: 0 5px 10px -3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 6px;
  }
  .personalAJ {
    border-radius: 50%;
  }
  .homeimg {
    max-height: 40px;
    max-width: 40px;
  }
}
