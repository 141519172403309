/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 70%;
  margin-bottom: 100px;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;

  height: 40vh;
  width: 100%;
  border-radius: 40px;
  background: rgba(0, 0, 0, 0.432);
}

/* Buttons */
.slideshowDots {
  text-align: center;
}
.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: #c4c4c4;
}
.inner_slide_container {
  height: inherit;
  display: flex;
  flex-direction: row;
}

.inner_slide_image {
  margin: 10px 10px;
  width: 30%;
  height: auto;
  object-fit: cover;
  border-radius: 10%;
}
.inner_slide_textbox {
  flex: 1;
  display: flex;
  margin-left: 30px;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  overflow: auto;
}
.inner_slide_header {
  color: white;
  font-size: 2rem;
  margin-top: 5%;

  text-align: left;
}
.inner_slide_text {
  height: 100%;

  white-space: break-spaces;
  color: rgb(255, 255, 255);
  text-align: left;
  font-weight: bold;
}
@media only screen and (max-device-width: 800px) {
  .slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 100%;
    margin-bottom: 100px;
    font-size: 75%;
  }
}
