h3 {
  text-align: left;
  font-size: 3rem;
}
.headerPage {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.header_section {
  background-image: url("../images/about_1.png");
  background-color: #cccccc;
  background-repeat: space;
  background-attachment: inherit;
  background-position: center;
  background-size: cover;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5vh;
}
.socials_container {
  padding-top: 15px;
  width: 100%;
  justify-content: center;
  height: 50px;
  display: flex;
}
.social {
  font-size: 30px;
}
.social_icon:hover .linkedin,
.social_icon .linkedin_hide {
  display: none;
}

.social_icon:hover .linkedin_hide {
  display: inline;
}
.social_icon:hover .instagram,
.social_icon .instagram_hide {
  display: none;
}

.social_icon:hover .instagram_hide {
  display: inline;
}
.social_icon:hover .github,
.social_icon .github_hide {
  display: none;
}

.social_icon:hover .github_hide {
  display: inline;
}
.social_icon:hover .email,
.social_icon .email_hide {
  display: none;
}

.social_icon:hover .email_hide {
  display: inline;
}
.social_icon:hover .vcard,
.social_icon .vcard_hide {
  display: none;
}

.social_icon:hover .vcard_hide {
  display: inline;
}
.social_icon {
  cursor: pointer;
}
.header_title {
  font-size: 3rem;
  color: white;
  font-weight: bold;
  width: 100%;
}

.section_2 {
  display: grid;
  height: 100%;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
}

.section_2_block1 {
  width: 60%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 50vh;
}
.clock_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0% 50%;
  border-radius: 15px;
}
.section_2_block2 {
  font-size: 150%;
  position: relative;
  height: fit-content;
  min-height: 15rem;
  width: 50%;
  margin-left: auto;
  margin-right: 5%;
  top: -100px;
  background: rgba(16, 40, 77);
  color: white;
  border-radius: 5px;
}
.vision_block_inside {
  margin: 5%;
}

.vision_block2_p {
  text-align: left;
  margin-top: 2rem;
}
.section_3 {
  display: grid;
  height: auto;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
}
.section_3_block1 {
  font-size: 150%;
  position: relative;
  height: min-content;
  min-height: 15rem;
  width: 50%;
  z-index: 1;
  margin-left: 10%;
  margin-top: 2rem;
  margin-right: auto;
  background: rgba(16, 40, 77);
  color: white;
  border-radius: 5px;
}
.section_3_block2 {
  top: -50%;
  width: 60%;
  position: relative;
  margin-left: 35%;
  margin-right: auto;
  height: 60vh;
  margin-bottom: -10%;
}

.education_block_inside {
  margin: 5%;
}

.section_4 {
  display: grid;
  height: auto;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
}
.section_4_block1 {
  font-size: 150%;
  position: relative;
  height: min-content;
  min-height: 15rem;
  width: 50%;
  z-index: 1;
  margin-right: 10%;
  margin-top: 2rem;
  margin-left: auto;
  background: rgba(16, 40, 77);
  color: white;
  border-radius: 5px;
}
.section_4_block2 {
  top: -80%;
  width: 70%;
  position: relative;
  margin-right: 35%;
  margin-left: 5%;
  height: 60vh;
  margin-bottom: -15%;
}

.education_block2_p {
  text-align: justify;
  margin-top: 2rem;
}
.section_5 {
  display: grid;
  height: auto;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
}
.section_5_block2 {
  width: 60%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 50vh;
}
.section_5_block1 {
  font-size: 150%;
  position: relative;
  height: fit-content;
  min-height: 15rem;
  width: 70%;
  margin-left: auto;
  margin-right: 5%;
  top: -100px;
  background: rgba(16, 40, 77);
  color: white;
  border-radius: 5px;
}

.social_icon {
  margin: 0 5vw; /* Adjust this value to control spacing between icons */
  min-width: 6vw;
}

.icon_text {
  font-size: 1vw;
}

@media only screen and (max-device-width: 800px) {
  .headerPage {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .icon_text {
    font-size: 2vw;
  }
  .section_2_block1 {
    width: 100%;
    height: 50vh;
  }

  .section_2_block2 {
    font-size: 80%;
    width: 90%;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .section_3_block1 {
    font-size: 80%;
    width: 80%;
    z-index: 1;
    margin-left: 10%;
    margin-top: 2rem;
    margin-right: auto;
    background: rgba(16, 40, 77);
    color: white;
    border-radius: 5px;
  }
  .section_3_block2 {
    width: 100%;
    margin-left: 3%;
  }

  .section_4_block1 {
    font-size: 80%;
    min-height: 15rem;
    width: 90%;
    z-index: 1;
    margin-right: auto;
    margin-top: 2rem;
    margin-left: auto;
    background: rgba(16, 40, 77, 0.9);
    color: white;
    border-radius: 5px;
  }
  .section_4_block2 {
    top: -80%;
    width: 130%;
    position: relative;

    margin-left: -20px;
    height: 60vh;
    margin-bottom: -15%;
  }

  .section_5_block2 {
    width: 100%;

    margin-left: auto;
    margin-right: auto;
    height: 50vh;
  }
  .section_5_block1 {
    font-size: 80%;

    min-height: 15rem;
    width: 90%;
    margin-left: auto;
    margin-right: 5%;
    background: rgba(16, 40, 77, 0.9);
    top: -250px;
    margin-bottom: -50%;
  }
}
